import React from 'react'

function Affiche() {
  return (
    <div className="card">
      <div className="card-content center-align">
        <img src="/images/semaine/2024/affiche.jpg" alt="Affiche 2024" className="responsive-img"/>
      </div>
    </div>
  )
}

export default Affiche
