import React from 'react';
import { EMAIL, TELEPHONE, TELEPHONE_URL } from '../../constants'

const Coordonnees = () => {

  return (
    <div>

      <div className="card">
        <div className="card-content">
          <span className="card-title">Contacter le Grand Maistre</span>
          <div className="row contact-coord">
            <div className="col s12 m4 l5">
              <div className="valign-center"><i className="material-icons contact-icon">phone</i>&nbsp;&nbsp;Par
                téléphone&nbsp;:&nbsp;
              </div>
            </div>
            <div className="col s12 m8 l7">
              <a className="grey-text text-darken-4 truncate" href={`tel:${TELEPHONE_URL}`}><b>{TELEPHONE}</b></a>
            </div>
          </div>

          <div className="row">
            <div className="col s12 m4 l5">
              <div className="valign-center"><i className="material-icons contact-icon">email</i>&nbsp;&nbsp;Par
                e-mail&nbsp;:&nbsp;
              </div>
            </div>
            <div className="col s12 m8 l7">
              <a className="grey-text text-darken-4 truncate"
                 href={`mailto:${EMAIL}`}><b>{EMAIL}</b></a>
            </div>
          </div>

          {/*<div className="row">*/}
            {/*<div className="col s12 m4 l5">*/}
              {/*<div className="valign-center"><i className="material-icons contact-icon">location_on</i>&nbsp;&nbsp;Par*/}
                {/*courrier&nbsp;:*/}
              {/*</div>*/}
            {/*</div>*/}
            {/*<div className="col s12 m8 l7 grey-text text-darken-4 truncate">*/}
              {/*<b>Ordre du Vénéré Bitard (<u>L.S.T.!</u>)*/}
                {/*<br/>{ADRESSE_1}<br/>{ADRESSE_2}</b>*/}
            {/*</div>*/}
          {/*</div>*/}
        </div>
      </div>

    </div>
  );

}

export default Coordonnees;
