import React from 'react';
import { Link } from 'react-router-dom'
import { CONTACT, COPAINS, FANFARE, ORDRE } from '../../constants'

function Plan() {

  return (
    <div>

      <div className="row">

        <div className="col s12 m6 l4">
          <Link to={ORDRE}>
            <div className="center promo promo-example">
              <i className="material-icons">account_balance</i>
              <p className="promo-caption">Ordre du Bitard (<u>L.S.T.!</u>)</p>
              <p className="light center">Présentation de l'Ordre du Vénéré Bitard (<u>L.S.T.!</u>)</p>
            </div>
          </Link>
        </div>

        <div className="col s12 m6 l4">
          <Link to={FANFARE}>
            <div className="center promo promo-example">
              <i className="material-icons">music_note</i>
              <p className="promo-caption">Fanfare</p>
              <p className="light center">Les Chiures de Mouches de Poitiers, la fanfare qui envoie du pâté !</p>
            </div>
          </Link>
        </div>

        <div className="col s12 m6 l4">
          <Link to={COPAINS}>
            <div className="center promo promo-example">
              <i className="material-icons">people</i>
              <p className="promo-caption">Copains</p>
              <p className="light center">Les amis de l'Ordre du Vénéré Bitard (<u>L.S.T.!</u>) et des Chiures de Mouches</p>
            </div>
          </Link>
        </div>

        <div className="col s12 m6 l4">
          <Link to={CONTACT}>
            <div className="center promo promo-example">
              <i className="material-icons">mail</i>
              <p className="promo-caption">Contact</p>
              <p className="light center">Tu veux contacter le Grand Maistre ? C'est par ici !</p>
            </div>
          </Link>
        </div>

      </div>


    </div>
  )

}

export default Plan
