import React from 'react'
import { Link } from 'react-router-dom'
import {
  ACCUEIL, CONTACT, FANFARE, ORDRE, EMAIL, TELEPHONE, TELEPHONE_URL,
  COPAINS
} from '../../constants'

function Footer() {
  return (
    <footer className="page-footer">
      <div className="container">
        <div className="row">
          <div className="col m3 s12 hide-on-small-and-down">
            <img src="/images/ecusson.png" className="logo" alt="L'Ordre du Bitard (L.S.T.!)"/>
          </div>
          <div className="col s5 m6 s12">
            <h5 className="white-text">Ordre du Bitard (<u>L.S.T.!</u>)</h5>
            {/*<p className="white-text text-lighten-4">{ADRESSE_1}<br/>{ADRESSE_2}</p>*/}
            <p className="white-text text-lighten-4">Tél : <a className="footer-link" href={`tel:${TELEPHONE_URL}`}>{TELEPHONE}</a>
              <br/>E-mail : <a className="footer-link"
                               href={`mailto:${EMAIL}`}>{EMAIL}</a></p>
          </div>
          <div className="col s4 m3 s12">
            <h5 className="white-text">Plan du site</h5>
            <ul>
              <li><Link className="footer-link" to={ACCUEIL}>Accueil</Link></li>
              <li><Link className="footer-link" to={ORDRE}>Ordre</Link></li>
              <li><Link className="footer-link" to={FANFARE}>Fanfare</Link></li>
              <li><Link className="footer-link" to={COPAINS}>Copains</Link></li>
              <li><Link className="footer-link" to={CONTACT}>Contact</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          © 2017-{new Date().getFullYear()} Ordre du Bitard (<u>L.S.T.!</u>)
        </div>
      </div>
    </footer>
  )
}

export default Footer