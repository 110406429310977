import React from 'react'
// import Tradition from './Tradition'
import Legende from './Legende'
// import Fonctions from './Fonctions'

function Ordre() {
  return (
    <div className="Container">
      <div className="row">
        {/*<div className="col s12 m6 l4">*/}
          {/*<Tradition/>*/}
        {/*</div>*/}
        {/*<div className="col s12 m6 l4">*/}
        <div className="col s12">
          <Legende/>
        </div>
        {/*<div className="col s12 m6 l4">*/}
          {/*<Fonctions/>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default Ordre