import React from 'react'
import CopainItem from './CopainItem'

function Copains() {
  return (
    <ul className="collection">
      <CopainItem
        imageSrc="/images/copains/voiture4.jpg"
        title="Voiture 4"
        slogan="La fanfare qui meule"
        ville="Paris"
        url="http://voiture4.free.fr" />
      <CopainItem
        imageSrc="/images/copains/myb.svg"
        title="Mouv' your Baudet"
        slogan="Fanfare Pictave"
        ville="Poitiers"
        url="https://mouvyourbaudet.com" />
      <CopainItem
        imageSrc="/images/copains/pistons.jpg"
        title="Fanfare Piston"
        slogan="Fanfare de l'Ecole Centrale de Lyon"
        ville="Lyon"
        url="http://www.fanfare-piston.org/" />
      <CopainItem
        imageSrc="/images/copains/grasse-bande.gif"
        title="La Grasse Bande"
        slogan="Disco Btp Distinction..."
        ville="Bordeaux"
        url="http://lagrassebande.fr" />
      <CopainItem
        imageSrc="/images/copains/pustule.jpg"
        title="Fanfare Pustule"
        slogan="La fanfare qui décapsule"
        ville="Lyon"
        url="http://pustule.org/" />
      <CopainItem
        imageSrc="/images/copains/fansmare.png"
        title="La Fansmare"
        slogan="Fanfare étudiante de l'ISAE-ENSMA"
        ville="Poitiers"
        url="http://www.fansmare.ensma.fr" />
    </ul>
  )
}

export default Copains
