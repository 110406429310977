import { SET_ACTIVE_INDEX, SET_SHOW_PLAYER, SET_SHOW_PLAYLIST } from '../actions'

const initialState = {
  activeIndex: 0,
  showPlayer: false,
  showPlaylist: false
}

const player = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_ACTIVE_INDEX :
      return { ...state, activeIndex: payload }
    case SET_SHOW_PLAYER:
      return { ...state, showPlayer: payload }
      case SET_SHOW_PLAYLIST:
      return { ...state, showPlaylist: payload }
    default:
      return state;
  }
}

export default player;

