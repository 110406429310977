import React from 'react'
import { FACEBOOK_PAGE } from '../../constants';

function Historique() {
  return (
      <div className="card">
        <div className="card-image">
          <img src="/images/fanfare.jpg" alt="Fanfare" className="materialboxed" data-caption="Les Chiures de Mouches"/>
          <span className="card-title">Historique</span>
        </div>
        <div className="card-content justify">
          <p>
            C’est au sein de l’Ordre du Vénéré Bitard (<u>L.S.T.!</u>), en 1964, qu’est née la
            première fanfare étudiante de Poitiers, connue sous le nom de <strong>Fanfare des Bitards</strong>.
            Elle ponctuait de manière festive la vie universitaire Pictave, notamment
            pendant la Semaine Estudiantine. Ces mêmes dignitaires de l’Ordre animaient
            des soirées à la Taverne avec le <strong>BBBB</strong>, à savoir le <strong>Big Bsychedilic Bitard’s Band</strong>.
          </p>
          <br/>
          <p>
            Depuis, la fanfare a toujours été présente dans la vie estudiantine
            Pictave, et plus particulièrement dans l’Ordre du Vénéré Bitard (<u>L.S.T.!</u>)
            Quelque peu essoufflée en 1973, la venue à la Semaine Estudiantine de la
            <strong>Fanfare des Bozarts</strong> de Tours donna une nouvelle impulsion
            et les dignitaires de l’Ordre se réunirent dans La Horde. A la fin des
            années 1970, <strong>La Horde</strong> fut rebaptisée le <strong>Réveil Phallique Pictave</strong>.
            C’est en 1976 que la prestation des fanfares des Beaux­‐Arts parisiennes
            pendant la Semaine Estudiantine a pu faire vibrer les tympans Pictaves.
          </p>
          <br/>
          <p>
            En 1981, la fanfare des Bitards prend définitivement le nom de <strong>Chiures de Mouches</strong>.
            Cette prestigieuse formation a permis à de nombreux virtuoses de s’exprimer
            et parfois de s’épanouir dans d’autres fanfares. Il en est ainsi des <strong>Rosa Mystica</strong>,
            nom inspiré du célèbre bréviaire, des <strong>Homos Erectus</strong>, des <strong>Marcels Fergusson</strong>,
            la bonne musique de la terre, des <strong>Nicotine Goudron</strong>, <strong>Croch’ en do
          </strong>, <strong>La famille Duhomard</strong>, la <strong>Voiture 4</strong>, les <strong>Mouv' your Baudet
          </strong> et les incontournables <strong>Los Minablos</strong>.
          </p>
          <br/>
          <p>
            Connue de tous et de renommée internationale, la fanfare des Chiures de
            Mouches a remportée la coupe du carnaval de Saint Jean d’Angély plusieurs
            années de suite. Celle ci a brillé lors du Tournoi International de sport
            en 2005 à Poitiers. Élue meilleur formation lors du concours des fanfares
            Pictaves à Paris en 2006, elle a également remporté prix du pâté au
            concours de fanfare étudiante en 2009 à Bordeaux.
          </p>
        </div>
        <div className="card-action">
          <a href={FACEBOOK_PAGE} target="_blank" rel="noopener">Voir la page Facebook des Chiures de Mouches</a>
        </div>
      </div>
  )
}

export default Historique