import React from 'react'
import Parallax from '../Parallax'
import Coordonnees from './Coordonnees'
import ReseauxSociaux from './ReseauxSociaux'

function Contact() {
  return (
    <div>

      <div className="row content">

        {/*<div className="col s12 m12 l6 contact-first-column">*/}
        <div className="col s12 m12 l12 contact-first-column">
          <Coordonnees/>
          <ReseauxSociaux/>
        </div>

        {/*<div className="col s12 m12 l6">*/}
          {/*<Form/>*/}
        {/*</div>*/}

      </div>
      <Parallax image="jacouille.jpg" alt="Contact"/>
    </div>
  )
}

export default Contact