import {
  ACCUEIL, ACCUEIL_TITLE, CONTACT, CONTACT_TITLE, COPAINS, COPAINS_TITLE, FANFARE, FANFARE_TITLE, ORDRE,
  ORDRE_TITLE
} from '../constants'

export const getRouter = (state) => state.router

export const getLocation = (state) => getRouter(state).location

export const getPathname = (state) => getLocation(state).pathname

export const getTitleFromPathname = (state) => {
  const pathname = getPathname(state)
  switch (pathname) {
    case ACCUEIL:
      return ACCUEIL_TITLE
    case ORDRE:
      return ORDRE_TITLE
    case FANFARE:
      return FANFARE_TITLE
    case COPAINS:
      return COPAINS_TITLE
    case CONTACT:
      return CONTACT_TITLE
    default:
      return ''
  }
}