import React from 'react';
import './App.scss';

import { Header, Footer, Accueil, Ordre, Fanfare, Copains, Contact, Erreur404, ScrollToTop } from './components'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import reducers from './reducers'

import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { ConnectedRouter, routerReducer, routerMiddleware } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
// import PlayListSoundPlayer from './components/Player/PlayListSoundPlayer'
// import { CLIENT_ID, RESOLVE_URL } from './constants'

const history = createHistory()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer
  }),
  composeEnhancers(
    applyMiddleware(thunk, routerMiddleware(history))
  ))

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ConnectedRouter history={history}>
          <div>
            <Header/>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={Accueil}/>
                <Route path="/ordre" component={Ordre}/>
                <Route path="/fanfare" component={Fanfare}/>
                <Route path="/copains" component={Copains}/>
                <Route path="/contact" component={Contact}/>
                <Route component={Erreur404}/>
              </Switch>
            </ScrollToTop>
            <Footer/>
            {/*<PlayListSoundPlayer clientId={CLIENT_ID}*/}
            {/*                     resolveUrl={RESOLVE_URL}/>*/}
          </div>
        </ConnectedRouter>
      </Router>
    </Provider>
  )
}

export default App;
