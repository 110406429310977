import {
  INIT, SET_EMAIL, SET_FIRST_NAME, SET_LAST_NAME, SET_MESSAGE, SET_MESSAGE_SENT, SET_PHONE,
  SET_SUBJECT, SET_ERROR
} from '../actions'

const initialState = {
  lastName: '',
  firstName: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
  messageSent: false,
  error: false
}


const contact = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case INIT:
      return { ...initialState }
    case SET_LAST_NAME:
      return { ...state, lastName: payload }
    case SET_FIRST_NAME:
      return { ...state, firstName: payload }
    case SET_EMAIL:
      return { ...state, email: payload }
    case SET_PHONE:
      return { ...state, phone: payload }
    case SET_SUBJECT:
      return { ...state, subject: payload }
    case SET_MESSAGE:
      return { ...state, message: payload }
    case SET_MESSAGE_SENT:
      return { ...state, messageSent: payload }
    case SET_ERROR:
      return { ...state, error: payload }
    default:
      return state
  }
}

export default contact;

