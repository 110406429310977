import { connect } from 'react-redux'
import Header from './Header.jsx'
import { getTitleFromPathname } from '../../selectors'

const mapStateToProps = (state) => {
  return {
    title: getTitleFromPathname(state)
  }
}

export default connect(mapStateToProps)(Header)