import React from 'react'

function Legende() {
  return (
    <div className="card">
      <div className="card-image">
        <img src="/images/venere.jpg" alt="Le Vénéré Bitard (<u>L.S.T.!</u>)" className="materialboxed" data-caption="Le Vénéré Bitard (L.S.T!)"/>
        <span className="card-title">Légende</span>
      </div>
      <div className="card-content justify">

        <p>Le dieu des <em>estudiants</em> de Poitiers trouve ses origines dans les temps anciens,
          quand les dieux habitaient encore l'Olympe.</p>
        <br/>
        <p>Junon, épouse de Jupiter, fauta un jour avec un berger. Le courroux de son mari
          fut sans limite et il en influenca le destin. Elle enfanta dans la douleur et
          le rejeton fut un monstre. Sa tête fut celle d'une fouine, le corps celui d'une
          carpe et pour se mouvoir il ne se vit doté que de deux pattes de lièvre. Mais
          il était aussi doté de magnifiques plumes de paon et portait les palmes ...
          Les palmes académiques.</p>
        <br/>
        <p>Cet être, dieu par sa mère, était le Vénéré Bitard (<u>L.S.T.!</u>).</p>
        <br/>
        <p>Pourtant Junon, horrifiée par l'aspect de son divin enfant, le projeta par
          dessus monts et vallées et ce dernier tomba dans les forêts de Ligugé. Par de
          nombreux miracles, il y fit connaître sa nature divine aux autochtones et ces
          derniers lui rendirent un culte en batissant un sanctuaire en son honneur.</p>
        <br/>
        <p>Au cinquième siècle, Saint Martin convertit de nombreux indigènes à sa religion.
          Le sanctuaire devint un couvent.</p>
        <br/>
        <p>Mais des initiés, refusant les idées nouvelles, s'enfuirent dans les bois avec
          les reliques du Vénéré Bitard (<u>L.S.T.!</u>) et enseignèrent leurs croyances à
          leurs enfants. C'est ainsi qu'au fil des siècles, leurs mystères furent transmis aux
          membres de l'Ordre du Vénéré Bitard (<u>L.S.T.!</u>).</p>
        <br/>
        <p>De nos jours, ces croyances sont toujours célébrées par les «escholiers fidèles»
          lors de réunions nocturnes et secrètes auxquelles ne sont invités que les
          dignitaires et, tous les ans, vers l'équinoxe de printemps, les <em>estudiants</em>,
          guidés par les dignitaires se livrent à la chasse au Vénéré Bitard (<u>L.S.T.!</u>).</p>
        <br/>
        <p>À l'issue de cette chasse, le nouveau <em>Grand Bitarder</em>, gardien du Vénéré Bitard
          (<u>L.S.T.!</u>) est présenté à travers la ville de Poitiers.</p>
        </div>
      </div>
  )
}

export default Legende