import React from 'react'

function Definition() {
  return (

    <div className="row">
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Définition</span>
            {/*<img src="/images/bitard-definition.png" alt="Bitardbourg" className="responsive-img"/>*/}
            <h5 className="definition">Bitard : (n.m.) Patois poitevin. Qui désigne l'outarde, l'oiseau de passage. De la base latinée bistarda. Mémoires de la Société des Antiquaires de l'Ouest, 1867, t. 32, p. 47</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Definition