import { getEmail, getFirstName, getLastName, getMessage, getPhone, getSubject } from '../selectors'
import { post } from '../services/emailServices'

export const INIT = 'contact/INIT'
export const SET_LAST_NAME = 'contact/SET_LAST_NAME'
export const SET_FIRST_NAME = 'contact/SET_FIRST_NAME'
export const SET_EMAIL = 'contact/SET_EMAIL'
export const SET_PHONE = 'contact/SET_PHONE'
export const SET_SUBJECT = 'contact/SET_SUBJECT'
export const SET_MESSAGE = 'contact/SET_MESSAGE'
export const SET_MESSAGE_SENT = 'contact/SET_MESSAGE_SENT'
export const SET_ERROR = 'contact/SET_ERROR'

export const init = () => ({ type: INIT })
export const setLastName = (payload) => ({ type: SET_LAST_NAME, payload })
export const setFirstName = (payload) => ({ type: SET_FIRST_NAME, payload })
export const setEmail = (payload) => ({ type: SET_EMAIL, payload })
export const setPhone = (payload) => ({ type: SET_PHONE, payload })
export const setSubject = (payload) => ({ type: SET_SUBJECT, payload })
export const setMessage = (payload) => ({ type: SET_MESSAGE, payload })
export const setMessageSent = (payload) => ({ type: SET_MESSAGE_SENT, payload })
export const setError = (payload) => ({ type: SET_ERROR, payload })

export const send = () => {
  return (dispatch, getState) => {
    const state = getState()
    const form = {
      firstName: getFirstName(state),
      lastName: getLastName(state),
      email: getEmail(state),
      phone: getPhone(state),
      subject: getSubject(state),
      message: getMessage(state)
    }

    post(form)
      .then((result) => {
          console.log('result : ', result)
          dispatch(setMessageSent(true))
          dispatch(setError(false))
        }
      )
      .catch((error) => {
        console.log('error : ', error)
        dispatch(setMessageSent(false))
        dispatch(setError(true))
      })


  }
}