import React from 'react'

export const ACCUEIL = '/'
export const ORDRE = '/ordre'
export const FANFARE = '/fanfare'
export const COPAINS = '/copains'
export const CONTACT = '/contact'

export const ACCUEIL_TITLE = 'Accueil'
export const ORDRE_TITLE = <span className="truncate">Ordre du Bitard <span className="hide-on-small-only-title">(<u>L.S.T.!</u>)</span></span>
export const FANFARE_TITLE = 'Fanfare'
export const COPAINS_TITLE = 'Copains'
export const CONTACT_TITLE = 'Contact'