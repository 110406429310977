import React from 'react'
import { string } from 'prop-types'

function CopainItem({ imageSrc, title, slogan, ville, url }) {
  return (
    <li className="collection-item avatar">
      <a href={url} target="_blank" rel="noopener">
        <img src={imageSrc} alt={title} className="circle"/>
        <span className="copain">{title}</span>
        <p className="slogan">{slogan}<br/>
          {ville}
        </p>
      </a>
    </li>
  )
}

CopainItem.propTypes = {
  imageSrc: string.isRequired,
  title: string.isRequired,
  slogan: string.isRequired,
  ville: string.isRequired,
  url: string.isRequired
}

export default CopainItem