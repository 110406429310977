import React from 'react'
import { FACEBOOK_PAGE, SOUNDCLOUD_PAGE } from '../../constants'

function ReseauxSociaux() {

  return (
    <div>

      <div className="card">
      <div className="card-content">

      <span className="card-title">Nous suivre sur les réseaux sociaux</span>
      <p className="justify">
        Retrouvez l'Ordre du Vénéré Bitard (<u>L.S.T.!</u>) et sa fanfare les Chiures de Mouches sur les réseaux sociaux <b>Facebook</b> et <b>Soundcloud</b>&nbsp;:
      </p>

      <div className="row center social-buttons">


        <div className="col s12 m6"><a
          className="grey-text text-lighten-3 waves-effect waves-light btn facebook-button"
          href={FACEBOOK_PAGE} target="_blank" rel="noopener"><i className="material-icons left">thumb_up</i>Facebook</a>
        </div>
        <div className="col s12 m6"><a
          className="grey-text text-lighten-3 waves-effect waves-light btn soundcloud-button"
          href={SOUNDCLOUD_PAGE} target="_blank" rel="noopener"><i
          className="material-icons left">cloud</i>Soundcloud</a></div>

      </div>

      <p className="justify">
        N'hésitez par à <b>Liker</b> et à <b>Partager</b> !
      </p>

    </div>
    </div>
    </div>
  )

}

export default ReseauxSociaux
