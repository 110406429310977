import '../node_modules/materialize-css/dist/css/materialize.min.css'
import '../node_modules/jquery/dist/jquery.slim.min'
import '../node_modules/materialize-css/dist/js/materialize.min'
import $ from 'jquery';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import registerServiceWorker from './registerServiceWorker';



window.$ = $;
window.jQuery = $;

ReactDOM.render(
    <App/>,
  document.getElementById('root'));
registerServiceWorker();
