import React, { Component } from 'react'
import { string, object, oneOfType } from 'prop-types'
import { NavLink } from 'react-router-dom';
import $ from 'jquery'
import { ACCUEIL, FANFARE, ORDRE, CONTACT, COPAINS } from '../../constants'

/*eslint-disable no-script-url*/
class Header extends Component {

  componentDidMount() {
    $(".button-collapse").sideNav({ edge: 'right' });
  }

  closeSideNav() {
    $('.button-collapse').sideNav('hide');
  }

  render() {
    const { title } = this.props
    return (
      <div>
        <div className="navbar-fixed">
          <nav>
            <div className="nav-wrapper">
              <a className="brand-logo hide-on-med-and-down">Ordre du Bitard (<u>L.S.T.!</u>)</a>
              <a className="brand-logo nav-title hide-on-large-only">{title}</a>
              <a href="" data-activates="mobile-demo" className="button-collapse right"><i
                className="material-icons">menu</i></a>
              <ul className="right hide-on-med-and-down">
                <li><NavLink activeClassName={'active'} to={ACCUEIL} exact>Accueil</NavLink></li>
                <li><NavLink activeClassName={'active'} to={ORDRE}>Ordre du Bitard (<u>L.S.T.!</u>)</NavLink></li>
                <li><NavLink activeClassName={'active'} to={FANFARE}>Fanfare</NavLink></li>
                <li><NavLink activeClassName={'active'} to={COPAINS}>Copains</NavLink></li>
                <li><NavLink activeClassName={'active'} to={CONTACT}>Contact</NavLink></li>
              </ul>
            </div>
          </nav>
        </div>

        <ul className="side-nav" id="mobile-demo">
          <nav>
            <div className="nav-wrapper" onClick={() => this.closeSideNav()}>
              <a href="javascript:void(0)" className="brand-logo nav-title left">Ordre du Bitard (<u>L.S.T.!</u>)</a>
              <ul id="nav-mobile" className="right">
                <li>
                  <i id="closePlayer" className="material-icons clickable modal-cross">close</i>
                </li>
              </ul>
            </div>
          </nav>
          <li>
            <NavLink activeClassName={'active'} onClick={this.closeSideNav} to={ACCUEIL} exact>
              <i className="material-icons">home</i>
              Accueil
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={'active'} onClick={this.closeSideNav} to={ORDRE}>
              <i className="material-icons">account_balance</i>
              Ordre du Bitard (<u>L.S.T.!</u>)
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={'active'} onClick={this.closeSideNav} to={FANFARE}>
              <i className="material-icons">music_note</i>
              Fanfare
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={'active'} onClick={this.closeSideNav} to={COPAINS}>
              <i className="material-icons">people</i>
              Copains
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={'active'} onClick={this.closeSideNav} to={CONTACT}>
              <i className="material-icons">email</i>
              Contact
            </NavLink>
          </li>
        </ul>

      </div>
    )
  }
}

Header.propTypes = {
  title: oneOfType([string, object])
}

export default Header