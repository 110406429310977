import { connect } from 'react-redux'
import Enregistrements from './Enregistrements.jsx'
import { setShowPlayer, setShowPlaylist } from '../../actions'

const mapDispatchToProps = (dispatch) => {
  return {
    setShowPlayer: (value) => dispatch(setShowPlayer(value)),
    setShowPlaylist: (value) => dispatch(setShowPlaylist(value)),
  }
}

export default connect(undefined, mapDispatchToProps)(Enregistrements)