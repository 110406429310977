import React, { Component } from 'react'
import { func } from 'prop-types'
// import $ from 'jquery'

/*eslint-disable no-script-url*/
class Enregistrements extends Component {

  // launchPlayer() {
  //   const { setShowPlayer, setShowPlaylist } = this.props
  //   setShowPlayer(true)
  //   setShowPlaylist(true)
  //   $('.modal').modal({ opacity: 0, dismissible: false });
  //   $('#modalPlaylist').modal('open');
  // }

  render() {
    return (

      <div className="card">
      <div className="card-image">
      <img src="/images/chiures-cover.jpg" alt="Fanfare" className="materialboxed" data-caption="Les Chiures de Mouches"/>
      <span className="card-title">Enregistrements</span>
  </div>
    <div className="card-content justify">

  </div>
    {/*<div className="card-action">*/}
    {/*  <a href="javascript:void(0)" onClick={() => this.launchPlayer()}>Ecouter l'album des 30 ans</a>*/}
    {/*</div>*/}
  </div>
    )
  }
}

Enregistrements.propTypes = {
  setShowPlayer: func,
  setShowPlaylist: func
}

export default Enregistrements
