import React from 'react'
import Historique from './Historique';
import PlayerLink from './Enregistrements'

function Fanfare() {
  return (
    <div className="Container">
      <div className="row">
        <div className="col s12 m6">
        <Historique/>
        </div>
        <div className="col s12 m6">
        <PlayerLink/>
        </div>
      </div>
    </div>
  )
}

export default Fanfare